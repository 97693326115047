<template>
  <div class='main-content'>
    <h2>{{ $t("dashboard-switcher.title") }}</h2>
    <div class="switcher">
      <ul>
        <li class='agents' v-if="isAdmin || isManager">
          <router-link to="/reports/agents-dashboard">
            <span class="link-icon link-icon-agents">
              <i class="fas fa-headset"></i>
            </span> {{ $t("dashboard-switcher.links.agents-dashboard" ) }}
          </router-link>
        </li>
        <li class='queues' v-if="isAdmin || isManager">
          <router-link to="/reports/queues-dashboard">
            <span class="link-icon link-icon-queues">
              <i class="fas fa-users "></i>
            </span> {{ $t("dashboard-switcher.links.queues-dashboard" ) }}
          </router-link>
        </li>
        <li class='extensions-dashboard' v-if="isAdmin || isReceptionist">
          <router-link to="/reports/extensions-dashboard">
            <span class="link-icon link-icon-queues">
              <i class="fas fa-users "></i>
            </span> {{ $t("dashboard-switcher.links.extensions-dashboard" ) }}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
    name : "DashboardsSwitcher",
    computed: {
      isReceptionist() {
        return this.$store.getters['getUser'].roles.includes('ROLE_RECEPTIONIST')
      },
      isAdmin() {
        return this.$store.getters['isAdmin']
      },
      isManager() {
        return this.$store.getters['getUser'].roles.includes('ROLE_MANAGER')
      }
    }
}
</script>
<style scoped>
.switcher.fancy{
  max-width: 600px;
  position: relative;
}
.switcher.fancy ul{
  flex-direction: column;
  gap: 0;
  left: 42%;
  margin: 0;
  position: absolute;
  top: 50%;
  width: 55%;
}

.switcher.fancy ul li{
  margin-bottom: .5em;
}
.switcher.fancy ul a{
  border-color: rgb(237,237,237);
  box-shadow: 0 0 5px rgba(227,227,227, .75);
  color: var(--hiperpbx-green);
  padding-bottom: 0.5em;
  padding-top: 0.5em;
}
.switcher.fancy ul a:hover{
  background: rgb(240,255,237);
  border-color: var(--hiperpbx-green);
  color: var(--hiperpbx-dark-green);
}
.switcher.fancy img{
  width: 100%;
}



.switcher ul{
  display: flex;
  gap: 10px 20px;
  list-style: none;
  margin-top: 2em;
}


.switcher ul a{
  background: white;
  border: 1px solid #F0F2F7;
  border-radius: 5px;
  color: var(--hiperpbx-grey);
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 1em;
  font-size: 1rem;
  text-decoration: none;
}

.link-icon{
  width: 2em;
  height: 2em;
  display: inline-block;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.link-icon-agents{
  color: var(--red-400);
  background-color: var(--red-100);
}

.link-icon-queues{
  color: var(--blue-400);
  background-color: var(--blue-100);
}

@media only screen and (max-width: 575px) {
  .switcher ul a{
    font-size: 0.8rem;
  }
}
</style>