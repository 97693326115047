<template>
  <div class="callhistory">
    <div style="display: inherit;">
      <el-tooltip class="item" effect="dark" placement="right" :content="$i18n.messages[$i18n.locale]['common'][call.type]">
        <span class="type"><i class="fas" :class="className"></i></span>
      </el-tooltip>
      <p class="bold">{{ call.displayname }}</p>
    </div>
    <div class="buttons">
      <div class="time">
        <p v-if="!isToday">{{ call.date | date }}</p>
        <p v-else>{{ call.date | hhmm }}</p>
      </div>
      <phone-button :janus="janus" :extension="call.number" :displayname="call.displayname" class="phone"></phone-button>
    </div>
  </div>
</template>

<script>
import PhoneButton from '../webrtc/PhoneButton'
import { mapGetters } from 'vuex'
export default {
  components: { 
    PhoneButton 
  },
  props : {
    call : {
      required : true
    }
  },
  computed : {
    ...mapGetters({
      janus : 'getJanus',
    }),
    className() {
      return {
        'fa-arrow-up': this.call.type === 'outgoing',
        'fa-arrow-down': this.call.type === 'ingoing',
        'fa-arrow-down lost': this.call.type === 'lost',
      }
    },
    isToday() {
      const today = new Date()
      const date = new Date(this.call.date)

      if (today.getFullYear() === date.getFullYear() && today.getDate() === date.getDate() && today.getMonth() === date.getMonth()) {
        return true
      }

      return false;
    }
  }
}
</script>

<style scoped>
  .callhistory {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    color: var(--blue-grey);
  }

  .time p {
    text-align: center;
  }


  .fa-arrow-up,
  .fa-arrow-down {
    font-size: 12px;
    transform: rotate(45deg);
  }
 
  .lost {
    color: var(--red);
  }

  .type {
    margin-right: var(--column);
  }

  .buttons {
    display: inline-flex;
    align-items: baseline;
  }

  .phone {
    margin-left: var(--column);
  }
</style>